import { NavLink } from "@remix-run/react";
import type { ProductTile } from "../../../../types/ProductTile";
import { GetLargeUrl, GetSmallUrl } from "~/utils/images";
import { formatPrice } from "~/utils/strings";
import { LinkWithIcon } from "./LinkWithIcon";

export const LargeProduct = ({
  url,
  brand,
  title,
  slug,
  price,
  discount,
  soldOut,
  height,
}: ProductTile & {
  url: string;
  height: "h-[400px]" | "h-[502px]" | "h-[371px]" | "h-[443px]";
}) => {
  return (
    <div className="flex flex-col">
      <NavLink
        prefetch="viewport"
        className="text-center"
        to={`/product/${slug}`}
        unstable_viewTransition={true}
      >
        {({ isTransitioning }) => (
          <div>
            <div className="relative flex flex-row items-center justify-center">
              {soldOut && (
                <div className="absolute left-0 top-0 z-10 bg-[#E3DACC] px-2 py-2 font-semibold">
                  Uppselt
                </div>
              )}
              <img
                src={GetLargeUrl(url)}
                loading="lazy"
                alt={title}
                className={`${height} min-w-[254px] rounded-md bg-white object-cover md:min-w-[346px]`}
                style={{
                  viewTransitionName: isTransitioning
                    ? "product-image"
                    : undefined,
                }}
              />
            </div>
          </div>
        )}
      </NavLink>
      <div className="mt-2">
        <div className="grid grid-cols-[1fr_auto]">
          <div className="flex h-full flex-col items-start justify-between gap-1">
            <div className="font-norma text-sm uppercase text-[#A3865A]">
              {brand}
            </div>
            <div className="text-base font-normal text-black">{title}</div>
            <div></div>
            <div className="flex flex-row items-center gap-2">
              <div
                className={`text-sm font-bold ${discount ? "line-through" : ""}`}
              >
                {formatPrice(price)}
              </div>
              {discount > 0 && (
                <div className="text-sm font-bold text-[#FF3636]">
                  {formatPrice(price * (1 - discount))}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col items-end justify-end">
            <LinkWithIcon
              to={`/product/${slug}`}
              title="Kaupa"
              textColor="black"
              size="small"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const SmallProduct = ({
  url,
  brand,
  title,
  slug,
  price,
  soldOut,
  hasVariations,
  discount,
}: ProductTile & {
  url: string;
  viewTransition?: boolean;
  hasVariations?: boolean;
  discount: number;
}) => {
  const prefetchLarge = () => {
    const img = new Image();
    img.src = GetLargeUrl(url);
  };
  return (
    <NavLink
      prefetch="intent"
      className="text-center"
      to={`/product/${slug}`}
      unstable_viewTransition={true}
    >
      {({ isTransitioning }) => (
        <div>
          <div className="relative flex flex-row items-center justify-center">
            {soldOut ? (
              <div className="absolute left-0 top-0 bg-[#E3DACC] px-2 py-2 font-semibold">
                Uppselt
              </div>
            ) : hasVariations ? (
              <div className="absolute left-0 top-0 bg-[#EFD4D7] px-2 py-2 font-semibold">
                Fleiri valmöguleikar
              </div>
            ) : null}
            <img
              loading="lazy"
              height={275}
              width={275}
              className="rounded-[6px] text-center"
              src={GetSmallUrl(url)}
              onFocus={prefetchLarge}
              onMouseEnter={prefetchLarge}
              alt={title}
              style={{
                viewTransitionName: isTransitioning
                  ? "product-image"
                  : undefined,
              }}
            />
          </div>
          <div className="mt-8 flex flex-col items-center">
            <div className="text-base text-[#A3865A] ">{brand}</div>
            <div className="text-base font-normal text-[#0E0F0F]">{title}</div>
            <div className="flex flex-row gap-x-2">
              <div
                className={`text-sm font-semibold text-[#0E0F0F] opacity-50 ${discount ? "line-through" : ""}`}
              >
                {formatPrice(price)}
              </div>
              {discount > 0 && (
                <div className="text-sm font-semibold text-[#FF3636]">
                  {formatPrice(price * (1 - discount))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </NavLink>
  );
};
